import { clickModalOpenTriggerEventHandler } from "./event-handlers/click-modal-open-trigger.js";
import { clickModalCloseTriggerEventHandler } from "./event-handlers/click-modal-close-trigger.js";

/**
 * The modal module.
 *
 * @return {void}
 */
export const modal = () => {
  const open = document.querySelectorAll(".js-accommodation-images-trigger");
  const close = document.querySelectorAll(".js-modal-close-trigger");

  if (open) {
    open.forEach((trigger) => {
      trigger.addEventListener("click", () => {
        clickModalOpenTriggerEventHandler(trigger);
      });
    });
  }

  if (close) {
    close.forEach((trigger) => {
      trigger.addEventListener("click", () => {
        clickModalCloseTriggerEventHandler(trigger);
      });
    });
  }
};

// Export the module
export default modal;
