import Swiper from "swiper/swiper-bundle";

export const OwnershipArchive = () => {
  let viewMoreCondition = [];
  let viewMoreBedrooms = [];
  let viewMoreType = [];
  let viewMoreMinPrice = 0;
  let viewMoreMaxPrice = 0;

  let condition = [];
  let bedrooms = [];
  let type = [];
  let minPrice = 0;
  let maxPrice = 0;

  const urlParams = new URLSearchParams(document.location.search);
  const paramSection = urlParams.get("section");
  const paramNumber = parseInt(urlParams.get("number"));

  const $headerOwnership = document.querySelector(".header__ownership");
  const $headerOwnershipMobile = document.querySelector(
    ".header__ownership__mobile"
  );

  const $ownershipArchive = document.querySelector(".ownership-archive");
  if ($ownershipArchive) {
    const $ownershipArchiveBar = $ownershipArchive.querySelector(
      ".ownership-archive__bar"
    );

    const $barSearch = $ownershipArchiveBar?.querySelector(".bar__search");

    const checkSearch = () => {
      if (minPrice !== 0 && maxPrice !== 0 && $barSearch) {
        $barSearch.classList.remove("opacity-75", "pointer-events-none");
        $barSearch.disabled = false;
      } else {
        $barSearch.classList.add("opacity-75", "pointer-events-none");
        $barSearch.disabled = true;
      }
    };
    const $ownershipBanners =
      $ownershipArchive.querySelector(".ownership-banners");

    const $ownershipOffers = $ownershipArchive.querySelector(
      ".ownership-archive__offers"
    );

    const $ownershipArchiveHomes = $ownershipArchive.querySelector(
      ".ownership-archive__homes"
    );

    const $homesSelect =
      $ownershipArchiveHomes?.querySelector(".homes__select");

    const $owernershipArciveCards =
      $ownershipArchiveHomes?.querySelectorAll(".js-ownership-card");
    const $homesLoad = $ownershipArchiveHomes?.querySelector(".homes__load");
    const $homesNone = $ownershipArchiveHomes?.querySelector(".homes__none");

    const $selectCondition = $ownershipArchiveBar?.querySelector(
      ".bar__select--condition"
    );
    const $selectBedrooms = $ownershipArchiveBar?.querySelector(
      ".bar__select--bedrooms"
    );
    const $selectType =
      $ownershipArchiveBar?.querySelector(".bar__select--type");

    const $ownershipFaqs = $ownershipArchive.querySelector(
      ".ownership-archive__faqs"
    );

    const $ownershipContactForm = document.querySelector(
      ".ownership-contact-form"
    );

    const setSelectValue = ($select, value) => {
      if ($select && value) {
        const $selectButton = $select.querySelector(".select__button");

        const $buttonText = $selectButton.querySelector(".button__text");

        const $selectValue = $select.querySelector(".select__value");

        const $selectDropdown = $select.querySelector(".select__dropdown");
        const $dropownOptions =
          $selectDropdown.querySelectorAll(".dropdown__option");

        $dropownOptions.forEach(($dropownOption) => {
          const $svgEmpty = $dropownOption.querySelector(
            ".js-booking-banner-option-empty"
          );
          const $svgSelected = $dropownOption.querySelector(
            ".js-booking-banner-option-selected"
          );

          if ($dropownOption.dataset.type === value) {
            if ($selectValue) {
              $selectValue.value = $dropownOption.dataset.type;
            }

            $buttonText.innerText = $dropownOption.dataset.label;
            $svgSelected.classList.remove("hidden");
            $svgEmpty.classList.add("hidden");
          } else {
            $svgEmpty.classList.remove("hidden");
            $svgSelected.classList.add("hidden");
          }
          $selectDropdown.classList.add("hidden");
          checkSearch();

          if ($selectButton.classList.contains("bg-white")) {
            $selectButton.classList.remove("bg-white");
            $selectButton.classList.add("bg-sky-blue");
          }
        });
      }
    };

    if ($ownershipArchiveBar) {
      const $barContents = $ownershipArchiveBar.querySelector(".bar__contents");
      const $barOpenContents = $ownershipArchiveBar.querySelector(
        ".bar__open-contents"
      );
      const $barOpen = $barOpenContents.querySelector(".bar__open");
      const $barClose = $ownershipArchiveBar.querySelector(".bar__close");

      $barOpen.addEventListener("click", (event) => {
        event.preventDefault();
        $ownershipArchiveBar.classList.remove("h-auto");
        $ownershipArchiveBar.classList.add("h-menu");
        $barContents.classList.remove("hidden");
        $barContents.classList.add("flex");
        $barOpenContents.classList.remove("block");
        $barOpenContents.classList.add("hidden");
      });
      $barClose.addEventListener("click", (event) => {
        event.preventDefault();
        $ownershipArchiveBar.classList.remove("h-menu");
        $ownershipArchiveBar.classList.add("h-auto");
        $barContents.classList.remove("flex");
        $barContents.classList.add("hidden");
        $barOpenContents.classList.remove("hidden");
        $barOpenContents.classList.add("block");
      });

      if ($barSearch) {
        $barSearch.addEventListener("click", (event) => {
          event.preventDefault();

          $ownershipArchiveBar.classList.remove("h-menu");
          $ownershipArchiveBar.classList.add("h-auto");
          $barContents.classList.remove("flex");
          $barContents.classList.add("hidden");
          $barOpenContents.classList.remove("hidden");
          $barOpenContents.classList.add("block");

          const localThings = [];
          let localCondition = [];
          let localType = [];

          if (condition.length === 0) {
            localCondition = ["new", "pre-owned"];
          } else {
            localCondition = condition;
          }

          if (type.length === 0) {
            localType = ["holiday-home", "luxury-lodge"];
          } else {
            localType = type;
          }

          localCondition.forEach((conditionInner) => {
            localType.forEach((typeInner) => {
              localThings.push(
                `${
                  conditionInner === "new" ? "" : `${conditionInner}-`
                }${typeInner}`
              );
            });
          });

          let cardCount = 0;
          let totalCardCount = 0;

          if ($owernershipArciveCards) {
            $owernershipArciveCards.forEach(($owernershipArciveCard) => {
              if (
                localThings.includes($owernershipArciveCard.dataset.type) &&
                (bedrooms.includes(
                  parseInt($owernershipArciveCard.dataset.bedrooms)
                ) ||
                  bedrooms.length === 0) &&
                parseInt($owernershipArciveCard.dataset.price) >= minPrice &&
                parseInt($owernershipArciveCard.dataset.price) <= maxPrice
              ) {
                if (cardCount < 6) {
                  $owernershipArciveCard.classList.remove("hidden");
                  cardCount += 1;
                } else {
                  $owernershipArciveCard.classList.add("hidden");
                }
                totalCardCount += 1;
              } else {
                $owernershipArciveCard.classList.add("hidden");
              }
            });
            if ($homesNone) {
              if (cardCount === 0) {
                $homesNone.classList.remove("hidden");
              } else {
                $homesNone.classList.add("hidden");
              }
              $ownershipArchiveHomes.scrollIntoView();
            }

            if ($homesLoad) {
              if (totalCardCount - 6 > 0) {
                const $homesLoadSpan = $homesLoad.querySelector("span");
                $homesLoadSpan.innerText = `View more (${totalCardCount - 6})`;
                $homesLoad.classList.remove("hidden");
              } else {
                $homesLoad.classList.add("hidden");
              }
            }
          }

          viewMoreCondition = condition;
          viewMoreBedrooms = bedrooms;
          viewMoreType = type;
          viewMoreMaxPrice = parseInt(maxPrice);
          viewMoreMinPrice = parseInt(minPrice);
        });
      }

      const $barSelects = $ownershipArchiveBar.querySelectorAll(".bar__select");
      $barSelects.forEach(($barSelect) => {
        const $selectButton = $barSelect.querySelector(".select__button");

        const $buttonText = $selectButton.querySelector(".button__text");
        const $buttonChevron = $selectButton.querySelector(".button__chevron");

        const $selectValue = $barSelect.querySelector(".select__value");

        const $selectDropdown = $barSelect.querySelector(".select__dropdown");
        const $dropownOptions =
          $selectDropdown.querySelectorAll(".dropdown__option");

        $selectButton.addEventListener("click", (event) => {
          event.preventDefault();
          $barSelects.forEach(($barSelectInner) => {
            const $selectDropdownInner =
              $barSelectInner.querySelector(".select__dropdown");
            const $selectButtonInner =
              $barSelectInner.querySelector(".select__button");
            const $buttonChevronInner =
              $selectButtonInner.querySelector(".button__chevron");
            if ($selectDropdownInner === $selectDropdown) {
              if ($selectDropdownInner.classList.contains("hidden")) {
                $selectDropdownInner.classList.remove("hidden");
                $buttonChevronInner.classList.add("transform", "rotate-180");
              } else {
                $selectDropdownInner.classList.add("hidden");
                $buttonChevronInner.classList.remove("transform", "rotate-180");
              }
            } else {
              $selectDropdownInner.classList.add("hidden");
              $buttonChevronInner.classList.remove("transform", "rotate-180");
            }
          });
        });

        $dropownOptions.forEach(($dropownOption) => {
          $dropownOption.addEventListener("click", (event) => {
            event.preventDefault();

            const $svgEmpty = $dropownOption.querySelector(
              ".js-booking-banner-option-empty"
            );
            const $svgSelected = $dropownOption.querySelector(
              ".js-booking-banner-option-selected"
            );

            const optionSelected = () => {
              $buttonChevron.classList.remove("transform", "rotate-180");
              $svgSelected.classList.remove("hidden");
              $svgEmpty.classList.add("hidden");
            };

            const optionEmpty = () => {
              $buttonChevron.classList.add("transform", "rotate-180");
              $svgSelected.classList.add("hidden");
              $svgEmpty.classList.remove("hidden");
            };

            const setBackgroundBlue = () => {
              $selectButton.classList.remove("bg-white");
              $selectButton.classList.add("bg-sky-blue");
            };

            const setBackgroundWhite = () => {
              $selectButton.classList.remove("bg-sky-blue");
              $selectButton.classList.add("bg-white");
            };

            $selectValue.value = $dropownOption.dataset.type;
            $buttonText.innerText = $dropownOption.dataset.label;

            if ($selectValue.name === "ownership-condition") {
              if (condition.includes($dropownOption.dataset.type)) {
                condition = condition.filter(
                  (conditionInner) =>
                    conditionInner !== $dropownOption.dataset.type
                );
                optionEmpty();
              } else {
                condition.push($dropownOption.dataset.type);
                optionSelected();
              }
              if (condition.length > 1) {
                $buttonText.innerText = "All";
                setBackgroundBlue();
              } else if (condition.includes("new")) {
                $buttonText.innerText = "New";
                setBackgroundBlue();
              } else if (condition.includes("pre-owned")) {
                $buttonText.innerText = "Pre-Owned";
                setBackgroundBlue();
              } else {
                $buttonText.innerText = "Condition";
                setBackgroundWhite();
              }
            } else if ($selectValue.name === "ownership-bedrooms") {
              if (bedrooms.includes(parseInt($dropownOption.dataset.type))) {
                bedrooms = bedrooms.filter(
                  (bedroomsInner) =>
                    bedroomsInner !== parseInt($dropownOption.dataset.type)
                );
                optionEmpty();
              } else {
                bedrooms.push(parseInt($dropownOption.dataset.type));
                optionSelected();
              }
              if (bedrooms.length > 1) {
                $buttonText.innerText = "All";
                setBackgroundBlue();
              } else if (bedrooms.includes(2)) {
                $buttonText.innerText = "2 Bedrooms";
                setBackgroundBlue();
              } else if (bedrooms.includes(3)) {
                $buttonText.innerText = "3 Bedrooms";
                setBackgroundBlue();
              } else {
                $buttonText.innerText = "Bedrooms";
                setBackgroundWhite();
              }
            } else if ($selectValue.name === "ownership-type") {
              if (type.includes($dropownOption.dataset.type)) {
                type = type.filter(
                  (typeInner) => typeInner !== $dropownOption.dataset.type
                );
                optionEmpty();
              } else {
                type.push($dropownOption.dataset.type);
                optionSelected();
              }
              if (type.length > 1) {
                $buttonText.innerText = "All";
                setBackgroundBlue();
              } else if (type.includes("holiday-home")) {
                $buttonText.innerText = "Holiday Home";
                setBackgroundBlue();
              } else if (type.includes("luxury-lodge")) {
                $buttonText.innerText = "Luxury Lodge";
                setBackgroundBlue();
              } else {
                $buttonText.innerText = "Type";
                setBackgroundWhite();
              }
            }

            $selectDropdown.classList.add("hidden");
            checkSearch();
          });
        });
      });

      const $barRanges = $ownershipArchiveBar.querySelectorAll(".bar__range");

      const sliderColour = "#2774AB4D";
      const rangeColour = "#2774AB";

      if ($barRanges) {
        const controlMinSlider = ($minSlider, $maxSlider, $minValue) => {
          const [from, to] = getParsed($minSlider, $maxSlider);
          fillSlider(
            $minSlider,
            $maxSlider,
            sliderColour,
            rangeColour,
            $maxSlider
          );
          if (from > to) {
            $minSlider.value = to;
            $minValue.value = to;
          } else {
            $minValue.value = from;
          }
        };

        const controlMaxSlider = ($minSlider, $maxSlider, $maxValue) => {
          const [from, to] = getParsed($minSlider, $maxSlider);
          fillSlider(
            $minSlider,
            $maxSlider,
            sliderColour,
            rangeColour,
            $maxSlider
          );
          setToggleAccessible($maxSlider, $maxSlider);
          if (from <= to) {
            $maxSlider.value = to;
            $maxValue.value = to;
          } else {
            $maxValue.value = from;
            $maxSlider.value = from;
          }
        };

        const getParsed = (currentFrom, currentTo) => {
          const from = parseInt(currentFrom.value, 10);
          const to = parseInt(currentTo.value, 10);
          return [from, to];
        };

        const fillSlider = (
          from,
          to,
          sliderColor,
          rangeColor,
          controlSlider
        ) => {
          const rangeDistance = to.max - to.min;
          const fromPosition = from.value - to.min;
          const toPosition = to.value - to.min;
          controlSlider.style.background = `linear-gradient(
            to right,
            ${sliderColor} 0%,
            ${sliderColor} ${(fromPosition / rangeDistance) * 100}%,
            ${rangeColor} ${(fromPosition / rangeDistance) * 100}%,
            ${rangeColor} ${(toPosition / rangeDistance) * 100}%, 
            ${sliderColor} ${(toPosition / rangeDistance) * 100}%, 
            ${sliderColor} 100%
          )`;
        };

        const setToggleAccessible = (currentTarget, $maxSlider) => {
          if (Number(currentTarget.value) <= 0) {
            $maxSlider.style.zIndex = 100;
          } else {
            $maxSlider.style.zIndex = 0;
          }
        };

        $barRanges.forEach(($barRange) => {
          const $rangeContainer = $barRange.querySelector(".range__container");

          const $rangeSliders =
            $rangeContainer.querySelector(".range__sliders");

          const $minSlider = $rangeSliders.querySelector(".min__slider");
          const $maxSlider = $rangeSliders.querySelector(".max__slider");
          const $minValue = $rangeContainer.querySelector(".min__value");
          const $maxValue = $rangeContainer.querySelector(".max__value");
          const $minText = $rangeContainer.querySelector(".min__text");
          const $maxText = $rangeContainer.querySelector(".max__text");
          fillSlider(
            $minSlider,
            $maxSlider,
            sliderColour,
            rangeColour,
            $maxSlider
          );
          setToggleAccessible($maxSlider, $maxSlider);

          const GBPound = new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });

          $minSlider.oninput = () => {
            controlMinSlider($minSlider, $maxSlider, $minValue);

            $minText.innerText = GBPound.format(parseInt($minValue.value));
            minPrice = parseInt($minValue.value);
            checkSearch();
          };

          $maxSlider.oninput = () => {
            controlMaxSlider($minSlider, $maxSlider, $maxValue);
            $maxText.innerText = GBPound.format(parseInt($maxValue.value));
            maxPrice = parseInt($maxValue.value);
            checkSearch();
          };

          controlMinSlider($minSlider, $maxSlider, $minValue);
          controlMaxSlider($minSlider, $maxSlider, $maxValue);

          $minText.innerText = GBPound.format(parseInt($minValue.value));
          minPrice = parseInt($minValue.value).toFixed(0);
          viewMoreMinPrice = parseInt($minValue.value).toFixed(0);

          $maxText.innerText = GBPound.format(parseInt($maxValue.value));
          maxPrice = parseInt($maxValue.value).toFixed(0);
          viewMoreMaxPrice = parseInt($maxValue.value).toFixed(0);
          checkSearch();
        });
      }
    }

    if ($ownershipBanners) {
      const $navigation = $ownershipBanners.querySelector(
        ".ownership-banners__navigation"
      );
      const $navigationPrev = $navigation.querySelector(".navigation__prev");
      const $navigationNext = $navigation.querySelector(".navigation__next");
      const $navigationBullets = $navigation.querySelector(
        ".navigation__bullets"
      );

      const $swiperImages = $ownershipBanners.querySelector(
        ".ownership-banners__swiper-images"
      );
      const $swiperInfo = $ownershipBanners.querySelector(
        ".ownership-banners__swiper-info"
      );
      if ($swiperImages && $swiperInfo) {
        const SwiperImages = new Swiper($swiperImages, {
          slidesPerView: 1,
          allowTouchMove: false,
        });
        const SwiperInfo = new Swiper($swiperInfo, {
          slidesPerView: 1,
          effect: "fade",
          fadeEffect: {
            crossFade: true,
          },
          navigation: {
            nextEl: $navigationNext,
            prevEl: $navigationPrev,
          },
          on: {
            slideChange: function (swiper) {
              SwiperImages.slideTo(swiper.activeIndex);
            },
          },
          pagination: {
            el: $navigationBullets,
            type: "bullets",
            bulletClass: "navigation__bullet",
            bulletActiveClass: "navigation__bullet--active",
            clickable: true,
          },
        });

        const goToSlide = (slide) => {
          $ownershipBanners.scrollIntoView();
          SwiperImages.slideTo(slide);
          SwiperInfo.slideTo(slide);
        };

        const $headerOwnershipBanners =
          $headerOwnership.querySelectorAll(".ownership__banner");
        $headerOwnershipBanners.forEach(($headerOwnershipBanner) => {
          $headerOwnershipBanner.addEventListener("click", (event) => {
            event.preventDefault();
            goToSlide(
              parseInt($headerOwnershipBanner.dataset.swiperSlideNumber)
            );
          });
        });

        const $headerOwnershipMobileBanners =
          $headerOwnershipMobile.querySelectorAll(".ownership__banner");
        $headerOwnershipMobileBanners.forEach(
          ($headerOwnershipMobileBanner) => {
            $headerOwnershipMobileBanner.addEventListener("click", (event) => {
              event.preventDefault();
              goToSlide(
                parseInt($headerOwnershipMobileBanner.dataset.swiperSlideNumber)
              );
            });
          }
        );

        if (paramSection === "banners" && Number.isInteger(paramNumber)) {
          goToSlide(paramNumber);
        }
      }
    }

    if ($ownershipOffers) {
      const $headerOwnershipOffers =
        $headerOwnership?.querySelector(".ownership__offers");

      const $headerOwnershipMobileOffers =
        $headerOwnershipMobile?.querySelector(".ownership__offers");

      if ($headerOwnershipOffers) {
        $headerOwnershipOffers.addEventListener("click", (event) => {
          event.preventDefault();
          $ownershipOffers.scrollIntoView();
        });
      }
      if ($headerOwnershipMobileOffers) {
        $headerOwnershipMobileOffers.addEventListener("click", (event) => {
          event.preventDefault();
          $ownershipOffers.scrollIntoView();
        });
      }
      if (paramSection === "offers") {
        $ownershipOffers.scrollIntoView();
      }
    }

    if ($ownershipArchiveHomes && $homesSelect) {
      const $selectButton = $homesSelect.querySelector(".select__button");
      const $buttonText = $selectButton.querySelector(".button__text");
      const $selectDropdown = $homesSelect.querySelector(".select__dropdown");

      const $dropownOptions =
        $selectDropdown.querySelectorAll(".dropdown__option");

      $selectButton.addEventListener("click", (event) => {
        event.preventDefault();
        $selectDropdown.classList.toggle("hidden");
      });

      $dropownOptions.forEach(($dropownOption) => {
        $dropownOption.addEventListener("click", (event) => {
          event.preventDefault();

          $dropownOptions.forEach(($dropownOptionInner) => {
            const $svgEmpty = $dropownOptionInner.querySelector(
              ".js-booking-banner-option-empty"
            );
            const $svgSelected = $dropownOptionInner.querySelector(
              ".js-booking-banner-option-selected"
            );

            if ($dropownOptionInner === $dropownOption) {
              $svgSelected.classList.remove("hidden");
              $svgEmpty.classList.add("hidden");
            } else {
              $svgEmpty.classList.remove("hidden");
              $svgSelected.classList.add("hidden");
            }
          });

          const localType = $dropownOption.dataset.type;
          $buttonText.innerText = $dropownOption.dataset.label;

          let cardCount = 0;
          let totalCardCount = 0;

          if (localType === "holiday-home") {
            setSelectValue($selectCondition, "new");
            condition = ["new"];
            viewMoreCondition = ["new"];
            setSelectValue($selectType, "holiday-home");
            type = ["holiday-home"];
            viewMoreType = ["holiday-home"];
          } else if (localType === "luxury-lodge") {
            setSelectValue($selectCondition, "new");
            condition = ["new"];
            viewMoreCondition = ["new"];
            setSelectValue($selectType, "luxury-lodge");
            type = ["luxury-lodge"];
            viewMoreType = ["luxury-lodge"];
          } else if (localType === "pre-owned-holiday-home") {
            setSelectValue($selectCondition, "pre-owned");
            condition = ["pre-owned"];
            viewMoreCondition = ["pre-owned"];
            setSelectValue($selectType, "holiday-home");
            type = ["holiday-home"];
            viewMoreType = ["holiday-home"];
          } else if (localType === "pre-owned-luxury-lodge") {
            setSelectValue($selectCondition, "pre-owned");
            condition = ["pre-owned"];
            viewMoreCondition = ["pre-owned"];
            setSelectValue($selectType, "luxury-lodge");
            type = ["luxury-lodge"];
            viewMoreType = ["luxury-lodge"];
          }

          checkSearch();

          if ($owernershipArciveCards) {
            $owernershipArciveCards.forEach(($owernershipArciveCard) => {
              if ($owernershipArciveCard.dataset.type === localType) {
                if (cardCount < 6) {
                  $owernershipArciveCard.classList.remove("hidden");
                  cardCount += 1;
                } else {
                  $owernershipArciveCard.classList.add("hidden");
                }
                totalCardCount += 1;
              } else {
                $owernershipArciveCard.classList.add("hidden");
              }
            });
            if ($homesNone) {
              if (cardCount === 0) {
                $homesNone.classList.remove("hidden");
              } else {
                $homesNone.classList.add("hidden");
              }
            }

            if ($homesLoad) {
              if (totalCardCount - 6 > 0) {
                const $homesLoadSpan = $homesLoad.querySelector("span");
                $homesLoadSpan.innerText = `View more (${totalCardCount - 6})`;
                $homesLoad.classList.remove("hidden");
              } else {
                $homesLoad.classList.add("hidden");
              }
            }
          }

          $selectDropdown.classList.add("hidden");
        });
      });

      if (
        paramSection === "holiday-home" ||
        paramSection === "luxury-lodge" ||
        paramSection === "pre-owned-holiday-home" ||
        paramSection === "pre-owned-luxury-lodge"
      ) {
        if (paramSection === "holiday-home") {
          setSelectValue($selectCondition, "new");
          condition = ["new"];
          viewMoreCondition = ["new"];
          setSelectValue($selectType, "holiday-home");
          type = ["holiday-home"];
          viewMoreType = ["holiday-home"];
          setSelectValue($homesSelect, "holiday-home");
        } else if (paramSection === "luxury-lodge") {
          setSelectValue($selectCondition, "new");
          condition = ["new"];
          viewMoreCondition = ["new"];
          setSelectValue($selectType, "luxury-lodge");
          type = ["luxury-lodge"];
          viewMoreType = ["luxury-lodge"];
          setSelectValue($homesSelect, "luxury-lodge");
        } else if (paramSection === "pre-owned-holiday-home") {
          setSelectValue($selectCondition, "pre-owned");
          condition = ["pre-owned"];
          viewMoreCondition = ["pre-owned"];
          setSelectValue($selectType, "holiday-home");
          type = ["holiday-home"];
          viewMoreType = ["holiday-home"];
          setSelectValue($homesSelect, "pre-owned-holiday-home");
        } else if (paramSection === "pre-owned-luxury-lodge") {
          setSelectValue($selectCondition, "pre-owned");
          condition = ["pre-owned"];
          viewMoreCondition = ["pre-owned"];
          setSelectValue($selectType, "luxury-lodge");
          type = ["luxury-lodge"];
          viewMoreType = ["luxury-lodge"];
          setSelectValue($homesSelect, "pre-owned-luxury-lodge");
        }
        checkSearch();

        $ownershipArchiveHomes.scrollIntoView();
      }

      if ($homesLoad) {
        $homesLoad.addEventListener("click", (event) => {
          event.preventDefault();
          let homesCount = 0;
          let totalHomesCount = 0;

          const localThings = [];
          let localCondition = [];
          let localType = [];

          if (viewMoreCondition.length === 0) {
            localCondition = ["new", "pre-owned"];
          } else {
            localCondition = condition;
          }

          if (viewMoreType.length === 0) {
            localType = ["holiday-home", "luxury-lodge"];
          } else {
            localType = type;
          }
          localCondition.forEach((conditionInner) => {
            localType.forEach((typeInner) => {
              localThings.push(
                `${
                  conditionInner === "new" ? "" : `${conditionInner}-`
                }${typeInner}`
              );
            });
          });

          $owernershipArciveCards.forEach(($owernershipArciveCard) => {
            if (
              localThings.includes($owernershipArciveCard.dataset.type) &&
              (viewMoreBedrooms.length !== 0
                ? viewMoreBedrooms.includes(
                    parseInt($owernershipArciveCard.dataset.bedrooms)
                  )
                : true) &&
              (viewMoreMinPrice !== 0
                ? parseInt($owernershipArciveCard.dataset.price) >=
                  viewMoreMinPrice
                : true) &&
              (viewMoreMaxPrice !== 0
                ? parseInt($owernershipArciveCard.dataset.price) <=
                  viewMoreMaxPrice
                : true)
            ) {
              if ($owernershipArciveCard.classList.contains("hidden")) {
                if (homesCount < 6) {
                  $owernershipArciveCard.classList.remove("hidden");
                  const $owernershipArciveCardSwiper =
                    $owernershipArciveCard.querySelector(".js-card-swiper");
                  $owernershipArciveCardSwiper.swiper.update();
                  homesCount += 1;
                }
                totalHomesCount += 1;
              }
            }
          });
          if (totalHomesCount <= 6) {
            $homesLoad.classList.add("hidden");
          } else {
            const $homesLoadSpan = $homesLoad.querySelector("span");
            $homesLoadSpan.innerText = `View more (${totalHomesCount - 6})`;
          }
        });
      }
    }

    if ($ownershipFaqs) {
      const $headerOwnershipFaqs =
        $headerOwnership?.querySelector(".ownership__faqs");

      const $headerOwnershipMobileFaqs =
        $headerOwnershipMobile?.querySelector(".ownership__faqs");

      if ($headerOwnershipFaqs) {
        $headerOwnershipFaqs.addEventListener("click", (event) => {
          event.preventDefault();
          $ownershipFaqs.scrollIntoView();
        });
      }

      if ($headerOwnershipMobileFaqs) {
        $headerOwnershipMobileFaqs.addEventListener("click", (event) => {
          event.preventDefault();
          $ownershipFaqs.scrollIntoView();
        });
      }

      if (paramSection === "faqs") {
        $ownershipFaqs.scrollIntoView();
      }
    }

    if ($ownershipContactForm) {
      const $headerOwnershipEnquire = $headerOwnership?.querySelector(
        ".ownership__enquire"
      );

      const $headerOwnershipMobileEnquire = document?.querySelector(
        ".ownership__enquire__mobile"
      );

      if ($headerOwnershipEnquire) {
        $headerOwnershipEnquire.addEventListener("click", (event) => {
          event.preventDefault();
          $ownershipContactForm.scrollIntoView();
        });
      }

      if ($headerOwnershipMobileEnquire) {
        $headerOwnershipMobileEnquire.addEventListener("click", (event) => {
          event.preventDefault();
          $ownershipContactForm.scrollIntoView();
        });
      }

      if (paramSection === "enquire") {
        $ownershipFaqs.scrollIntoView();
      }
    }

    if ($headerOwnership) {
      const $ownershipLinks = $headerOwnership.querySelectorAll(
        ".ownership__links .ownership__link"
      );

      const $ownershipMobileLinks = $headerOwnershipMobile.querySelectorAll(
        ".ownership__links .ownership__link"
      );
      const $ownershipImages = $headerOwnership.querySelectorAll(
        ".ownership__images .ownership__image"
      );

      const goToCards = (localType) => {
        let cardCount = 0;
        let totalCardCount = 0;

        if (localType === "holiday-home") {
          setSelectValue($selectCondition, "new");
          condition = ["new"];
          viewMoreCondition = ["new"];
          setSelectValue($selectType, "holiday-home");
          type = ["holiday-home"];
          viewMoreType = ["holiday-home"];
          setSelectValue($homesSelect, "holiday-home");
        } else if (localType === "luxury-lodge") {
          setSelectValue($selectCondition, "new");
          condition = ["new"];
          viewMoreCondition = ["new"];
          setSelectValue($selectType, "luxury-lodge");
          type = ["luxury-lodge"];
          viewMoreType = ["luxury-lodge"];
          setSelectValue($homesSelect, "luxury-lodge");
        } else if (localType === "pre-owned-holiday-home") {
          setSelectValue($selectCondition, "pre-owned");
          condition = ["pre-owned"];
          viewMoreCondition = ["pre-owned"];
          setSelectValue($selectType, "holiday-home");
          type = ["holiday-home"];
          viewMoreType = ["holiday-home"];
          setSelectValue($homesSelect, "pre-owned-holiday-home");
        } else if (localType === "pre-owned-luxury-lodge") {
          setSelectValue($selectCondition, "pre-owned");
          condition = ["pre-owned"];
          viewMoreCondition = ["pre-owned"];
          setSelectValue($selectType, "luxury-lodge");
          type = ["luxury-lodge"];
          viewMoreType = ["luxury-lodge"];
          setSelectValue($homesSelect, "pre-owned-luxury-lodge");
        }
        checkSearch();

        if ($owernershipArciveCards) {
          $owernershipArciveCards.forEach(($owernershipArciveCard) => {
            if ($owernershipArciveCard.dataset.type === localType) {
              if (cardCount < 6) {
                $owernershipArciveCard.classList.remove("hidden");
                cardCount += 1;
              } else {
                $owernershipArciveCard.classList.add("hidden");
              }
              totalCardCount += 1;
            } else {
              $owernershipArciveCard.classList.add("hidden");
            }
          });
          if ($homesNone) {
            if (cardCount === 0) {
              $homesNone.classList.remove("hidden");
            } else {
              $homesNone.classList.add("hidden");
            }
            $ownershipArchiveHomes.scrollIntoView();
          }

          if ($homesLoad) {
            if (totalCardCount - 6 > 0) {
              const $homesLoadSpan = $homesLoad.querySelector("span");
              $homesLoadSpan.innerText = `View more (${totalCardCount - 6})`;
              $homesLoad.classList.remove("hidden");
            } else {
              $homesLoad.classList.add("hidden");
            }
          }

          const $ownershipLodges =
            $headerOwnership.querySelector(".ownership__lodges");
          $ownershipLodges.parentNode.classList.add("pointer-events-none");
          $ownershipArchiveHomes.scrollIntoView();
          //
          setInterval(() => {
            $ownershipLodges.parentNode.classList.remove("pointer-events-none");
          }, 500);
        }
      };

      $ownershipLinks.forEach(($ownershipLink) => {
        $ownershipLink.addEventListener("mouseover", (event) => {
          $ownershipImages.forEach(($ownershipImage) => {
            if ($ownershipImage.dataset.type === $ownershipLink.dataset.type) {
              $ownershipImage.classList.remove("hidden");
            } else {
              $ownershipImage.classList.add("hidden");
            }
          });
        });
        $ownershipLink.addEventListener("click", (event) => {
          event.preventDefault();
          goToCards($ownershipLink.dataset.type);
        });
      });

      $ownershipMobileLinks.forEach(($ownershipMobileLink) => {
        $ownershipMobileLink.addEventListener("click", (event) => {
          event.preventDefault();
          goToCards($ownershipMobileLink.dataset.type);
        });
      });
    }
  }
};

export default OwnershipArchive;
