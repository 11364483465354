/**
 * Functionality for the sticky banner.
 *
 * @param  {object}  event  The event object.
 * @return {void}
 */
export const stickyBanner = ($banner) => {
  const target = document.querySelector(".js-sticky-booking-banner-target");
  const background = document.querySelector(".js-sticky-booking-banner-background");
  const position = target.getBoundingClientRect();
  const top = position.top + window.scrollY;
  let inView = false;

  // Disable if on mobile
  if (window.innerWidth < 768){
    $banner.classList.add("hidden");
    background.classList.add("hidden");
  } else {
    document.addEventListener("scroll", () => {
      const scrolled = window.scrollY;
      // Activate sticky
      if (scrolled >= top-110){
        $banner.classList.add("fixed");
        $banner.classList.add("top-28");
        background.classList.remove("h-0");
        background.classList.add("h-20");
        inView = true;
      } else if (scrolled < top-110){
        // Deactivate sticky
        $banner.classList.remove("fixed");
        $banner.classList.remove("top-28");
        background.classList.add("h-0");
        background.classList.remove("h-20");
        inView = false;
      }
    });

    $banner.addEventListener("click", () => {
      if (inView === false){
        $banner.scrollIntoView({behavior: "smooth"});
      }
    })
  }
};

// Export the module
export default stickyBanner;
