/**
 * Event handler to close a modal.
 *
 * @param  {object}  event  The event object.
 * @return {void}
 */
export const clickModalCloseTriggerEventHandler = ($trigger) => {
  // Set the elements
  const target = $trigger.dataset.target;
  const $modal = document.querySelector(".js-modal");
  const $targetModal = document.querySelector(target);
  const $body = document.getElementById("body");

  // Stop scrolling
  $body.classList.remove("no-scrolling");

  // Hide modal
  $modal.classList.add("opacity-0");
  $modal.classList.add("pointer-events-none");
  $targetModal.classList.remove("h-full");
  $targetModal.classList.add("h-0");
  $targetModal.classList.add("opacity-0");
  $targetModal.classList.add("pointer-events-none");
};

// Export the module
export default clickModalCloseTriggerEventHandler;
