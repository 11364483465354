import { helloWorld } from "./functions/hello-world.js";
import { getTargetSelectorParent } from "./functions/get-target-selector-parent.js";
import { isTargetSelector } from "./functions/is-target-selector.js";
import { scrollToElement } from "./functions/scroll-to-element.js";
import { trapFocus } from "./functions/trap-focus.js";

export const helper = {
  /**
   * Log hello world to the console.
   *
   * @return {void}
   */
  helloWorld: () => {
    // Log hello world to the console
    helloWorld();
  },

  /**
   * Get the event target or an event target parent based by selector.
   *
   * @param  {element}  target     The event target.
   * @param  {string}   attribute  The event target attribute to check.
   * @param  {string}   selector   The id/class selector.
   * @return {element}             The event target selector, event target selector ancestor, false otherwise.
   */
  getTargetSelectorParent: (target, attribute, selector) => {
    // Return the event target selector, event target selector ancestor, false otherwise
    return getTargetSelectorParent(target, attribute, selector);
  },

  /**
   * Check if an event target is a target selector or a descendant of a target selector.
   *
   * @param  {element}  target     The event target.
   * @param  {string}   attribute  The event target attribute to check.
   * @param  {string}   selector   The id/class selector.
   * @return {bool}                True if event target, false otherwise.
   */
  isTargetSelector: (target, attribute, selector) => {
    // Return true if event target, false otherwise
    return isTargetSelector(target, attribute, selector);
  },

  /**
   * Scroll to an element.
   *
   * @param  {element}  $element  The element to scroll to.
   * @param  {int}      offsetX   The x axis offset.
   * @param  {int}      offsetY   The y axis offset.
   * @param  {string}   behavior  The scroll behavior.
   * @return {void}
   */
  scrollToElement: ($element, offsetX = 0, offsetY = 0, behavior = "auto") => {
    // Scroll to an element
    scrollToElement($element, offsetX, offsetY, behavior);
  },

  /**
   * Trap focus to an element.
   *
   * @param  {element}  $element  The element.
   * @return {void}
   */
  trapFocus: ($element) => {
    // Trap focus to an element
    trapFocus($element);
  },
};

// Export the module
export default helper;
