import scrollSmootherFunction from "./functions/scroll-smoother";

export const animations = () => {
  // set elements
  const smoothScrollElements = document.querySelector("#smooth-content");

  if (smoothScrollElements) {
    scrollSmootherFunction(smoothScrollElements);
  }
};

// Export the module
export default animations;
